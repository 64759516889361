// Generated by Framer (2ca17d4)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Link, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const enabledGestures = {iN8ndot3W: {hover: true}};

const cycleOrder = ["iN8ndot3W", "ev0s9uJC6"];

const serializationHash = "framer-jiHe5"

const variantClassNames = {ev0s9uJC6: "framer-v-y0i6sp", iN8ndot3W: "framer-v-20udky"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 50, delay: 0, mass: 1, stiffness: 300, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const humanReadableVariantMap = {Default: "iN8ndot3W", Selected: "ev0s9uJC6"}

const getProps = ({height, id, link, title, width, ...props}) => { return {...props, FcK_TvsYr: title ?? props.FcK_TvsYr ?? "Affiliate", QuWKPWgPR: link ?? props.QuWKPWgPR, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "iN8ndot3W"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;title?: string;link?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, FcK_TvsYr, QuWKPWgPR, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "iN8ndot3W", enabledGestures, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const sharedStyleClassNames = []

const scopingClassNames = cx(serializationHash, ...sharedStyleClassNames)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}><Variants animate={variants} initial={false}><Transition value={transition1}><Link href={QuWKPWgPR} motionChild nodeId={"iN8ndot3W"} openInNewTab={false} smoothScroll><motion.a {...restProps} {...gestureHandlers} className={`${cx(scopingClassNames, "framer-20udky", className, classNames)} framer-1hy7zvo`} data-framer-name={"Default"} layoutDependency={layoutDependency} layoutId={"iN8ndot3W"} ref={ref ?? ref1} style={{backgroundColor: "rgba(0, 0, 0, 0)", borderBottomLeftRadius: 4, borderBottomRightRadius: 4, borderTopLeftRadius: 4, borderTopRightRadius: 4, ...style}} variants={{"iN8ndot3W-hover": {backgroundColor: "var(--token-6839e435-35f3-4ab3-b723-e98c27e525bc, rgba(255, 255, 255, 0.1))"}, ev0s9uJC6: {backgroundColor: "var(--token-6839e435-35f3-4ab3-b723-e98c27e525bc, rgba(255, 255, 255, 0.1))"}}} {...addPropertyOverrides({"iN8ndot3W-hover": {"data-framer-name": undefined}, ev0s9uJC6: {"data-framer-name": "Selected"}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--framer-font-size": "14px", "--framer-text-color": "var(--extracted-r6o4lv, rgb(158, 161, 186))"}}>Affiliate</motion.p></React.Fragment>} className={"framer-ueou0q"} fonts={["Inter"]} layoutDependency={layoutDependency} layoutId={"YWiIdLZoO"} style={{"--extracted-r6o4lv": "rgb(158, 161, 186)", "--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline"}} text={FcK_TvsYr} verticalAlignment={"top"} withExternalLayout/></motion.a></Link></Transition></Variants></LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-jiHe5.framer-1hy7zvo, .framer-jiHe5 .framer-1hy7zvo { display: block; }", ".framer-jiHe5.framer-20udky { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: visible; padding: 5px 8px 5px 8px; position: relative; text-decoration: none; width: min-content; }", ".framer-jiHe5 .framer-ueou0q { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-jiHe5.framer-20udky { gap: 0px; } .framer-jiHe5.framer-20udky > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-jiHe5.framer-20udky > :first-child { margin-left: 0px; } .framer-jiHe5.framer-20udky > :last-child { margin-right: 0px; } }", ".framer-jiHe5.framer-v-y0i6sp.framer-20udky { cursor: unset; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 27
 * @framerIntrinsicWidth 66
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"ev0s9uJC6":{"layout":["auto","auto"]},"HNorUUuFo":{"layout":["auto","auto"]}}}
 * @framerVariables {"FcK_TvsYr":"title","QuWKPWgPR":"link"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const Framergbt_uV6JC: React.ComponentType<Props> = withCSS(Component, css, "framer-jiHe5") as typeof Component;
export default Framergbt_uV6JC;

Framergbt_uV6JC.displayName = "nav items 4";

Framergbt_uV6JC.defaultProps = {height: 27, width: 66};

addPropertyControls(Framergbt_uV6JC, {variant: {options: ["iN8ndot3W", "ev0s9uJC6"], optionTitles: ["Default", "Selected"], title: "Variant", type: ControlType.Enum}, FcK_TvsYr: {defaultValue: "Affiliate", displayTextArea: false, title: "Title", type: ControlType.String}, QuWKPWgPR: {title: "Link", type: ControlType.Link}} as any)

addFonts(Framergbt_uV6JC, [{explicitInter: true, fonts: [{family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F", url: "https://framerusercontent.com/assets/5vvr9Vy74if2I6bQbJvbw7SY1pQ.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116", url: "https://framerusercontent.com/assets/EOr0mi4hNtlgWNn9if640EZzXCo.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+1F00-1FFF", url: "https://framerusercontent.com/assets/Y9k9QrlZAqio88Klkmbd8VoMQc.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0370-03FF", url: "https://framerusercontent.com/assets/OYrD2tBIBPvoJXiIHnLoOXnY9M.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF", url: "https://framerusercontent.com/assets/JeYwfuaPfZHQhEG8U5gtPDZ7WQ.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD", url: "https://framerusercontent.com/assets/vQyevYAyHtARFwPqUzQGpnDs.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB", url: "https://framerusercontent.com/assets/b6Y37FthZeALduNqHicBT6FutY.woff2", weight: "400"}]}], {supportsExplicitInterCodegen: true})